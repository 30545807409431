<template>
  <v-calendar
    :rows="$screens({ default: 8, md: 4, lg: 3, xl: 2 })"
    :columns="$screens({ default: 1, md: 2, lg: 3, xl: 4 })"
    :from-date="new Date(year, 3, 1)"
    :min-date="new Date(year, 3, 1)"
    :max-date="new Date(year, 10, 30)"
    :attributes="bookingList"
  >
    <template #day-popover="{ day, format, masks, attributes }">
      <div>
        {{ format(day.date, masks.dayPopover) }}
        <el-descriptions
          :column="1"
          :label="attr.customData.owner"
          size="mini"
          v-for="attr in attributes"
          :key="attr.key"
          border
        >
          <template #extra>
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              size="mini"
              @click.stop="onEdit(attr.customData.id)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              size="mini"
              @click.stop="onDelete(attr.customData.id)"
            ></el-button>
          </template>

          <el-descriptions-item label="Owner">
            {{ attr.customData.owner }}
          </el-descriptions-item>
          <el-descriptions-item label="Start">
            {{
              new Date(
                attr.customData.startDate.seconds * 1000
              ).toLocaleDateString()
            }}</el-descriptions-item
          >
          <el-descriptions-item label="End">
            {{
              new Date(
                attr.customData.endDate.seconds * 1000
              ).toLocaleDateString()
            }}
          </el-descriptions-item>
          <el-descriptions-item label="Comment">{{
            attr.customData.comment
          }}</el-descriptions-item>
          <el-descriptions-item label="Created by">
            {{ attr.customData.creator }}</el-descriptions-item
          >
          <el-descriptions-item label="Created at">{{
            new Date(attr.customData.created.seconds * 1000).toLocaleString()
          }}</el-descriptions-item>
        </el-descriptions>
      </div>
    </template>
  </v-calendar>
</template>

<script>
export default {
  name: "Calendar",
  data() {
    return {};
  },
  computed: {
    bookingList() {
      var data = [
        {
          key: "today",
          highlight: true,
          fillMode: "light",
          dates: new Date(),
        },
      ];
      console.log(this.bookings);
      for (const idx in this.bookings) {
        const booking = this.bookings[idx];
        const color = this.users.get(booking.owner.toLowerCase()).color;
        console.log(booking);
        data.push({
          key: booking.id,
          popover: true,
          customData: booking,
          highlight: {
            start: { fillMode: "solid", color: color },
            base: { fillMode: "solid", color: color },
            end: { fillMode: "solid", color: color },
          },
          dates: {
            start: new Date(booking.startDate.seconds * 1000),
            end: new Date(booking.endDate.seconds * 1000),
          },
        });
      }
      return data;
    },
  },
  emits: ["busy"],
  inject: ["bookings", "backend", "users"],
  methods: {
    onEdit(id) {
      console.log(id);
      return;
    },
    onDelete(id) {
      this.$emit("busy", true);
      this.backend.deleteBooking(id).then(
        () => {
          this.$emit("busy", false);
        },
        (err) => {
          this.$emit("busy", false);
          alert(err);
        }
      );
    },
  },
  props: {
    year: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },
};
</script>

<style scoped>
#calender {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  border-spacing: 10px; /*Optional*/
}
.popover-entry {
  border-top: 1px solid #fff;
}
</style>