<template>
  <el-form id="login">
    <el-alert
      class="alert"
      title="Login failed."
      type="error"
      v-if="errorMessage"
    >
    </el-alert>
    <el-form-item>
      <el-input
        name="username"
        v-model="input.username"
        placeholder="Username"
        @keyup.enter="login()"
      /> </el-form-item
    ><el-form-item>
      <el-input
        name="password"
        v-model="input.password"
        placeholder="Password"
        show-password
        @keyup.enter="login()"
      />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="login()">Login</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { inject } from "vue";
export default {
  name: "Login",
  setup() {
    const backend = inject("backend");
    return {
      backend,
    };
  },
  data() {
    return {
      errorMessage: "",
      input: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    login() {
      var self = this;
      if (this.input.username === "" || this.input.password === "") {
        console.log("A username and password must be present");
        return;
      }
      this.backend.setAuthData(this.input.username, this.input.password);
      this.backend.getBookings().then(
        () => null,
        () => (self.errorMessage = true)
      );
    },
  },
};
</script>

<style scoped>
#login {
  width: 50%;
  max-width: 300px;
  margin: auto;
  margin-top: 200px;
  padding: 20px;
}
.alert {
  margin-bottom: 20px;
}
</style>