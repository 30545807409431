import { LodkaServiceClient } from "./lodkapb/lodka_grpc_web_pb";
import {
  GetBookingsRequest,
  CreateBookingRequest,
  DeleteBookingRequest,
  Status,
  Auth
} from "./lodkapb/lodka_pb";

class Backend {
  constructor(url) {
    this.client = new LodkaServiceClient(url, null, null);
    this.auth = undefined;
    this.authenticated = false;
    this.onDataChange = [];
    this.onAuthChange = [];

    this._loadAuthData();
    console.log("Backend instance created with: ", url);
  }

  // Loads credentials from local storage
  _loadAuthData() {
    let username = sessionStorage.getItem("loginUser");
    let password = sessionStorage.getItem("loginPass");

    if (typeof username !== "string" && typeof password !== "string") {
      this.auth = undefined;
      return;
    }

    this.setAuthData(username, password);
  }

  // Saves credentials to local storage
  _saveAuthData() {
    if (typeof this.auth !== "object") {
      sessionStorage.setItem("loginUser", null);
      sessionStorage.setItem("loginPass", null);
      this.setAuthenticated(false);
    } else {
      sessionStorage.setItem("loginUser", this.auth.getUser());
      sessionStorage.setItem("loginPass", this.auth.getSecret());
    }
  }

  addOnDataChange(callback) {
    this.onDataChange.push(callback);
  }

  addOnAuthChange(callback) {
    this.onAuthChange.push(callback);
  }

  _callAllCallbacks(collection, arg) {
    for (const cb of collection) {
      cb(arg);
    }
  }

  setAuthenticated(state) {
    if (this.authenticated !== state) {
      console.log("Auth state changed:", this.authenticated, state);
      this.authenticated = state;
      this._callAllCallbacks(this.onAuthChange, state);
    }
  }

  isAuthenticed() {
    return this.authenticated;
  }

  getUser() {
    if (typeof this.auth !== "object") {
      return "";
    }
    return this.auth.getUser();
  }

  // Creates Auth object to be used for API requests
  setAuthData(username, password) {
    if (username === "" && password === "") {
      this.clearAuthData();
      return;
    }
    this.auth = new Auth();
    this.auth.setUser(username);
    this.auth.setSecret(password);
    this._saveAuthData();
  }

  // Clears credentials
  clearAuthData() {
    this.auth = undefined;
    this.setAuthenticated(false);
    this._saveAuthData();
  }

  _getRequestPromise(endpoint, request) {
    var self = this;
    var endpoint_function = this.client[endpoint].bind(this.client);
    console.log("Calling API endpoint:", endpoint);
    return new Promise(function (resolve, reject) {
      if (typeof self.auth === "undefined") {
        reject("Auth not set");
        return;
      }
      request.setAuth(self.auth);

      endpoint_function(request, {}, (err, response) => {
        if (err !== null) {
          console.log(endpoint, "Response failed:", err);
          reject(err);
          return;
        }

        let resp = response.toObject();
        console.log(endpoint, "Response code:", resp.responseStatus.code);
        if (resp.responseStatus.code === Status.Code.AUTH_ERROR) {
          self.setAuthenticated(false);
          reject(resp.responseStatus.code);
          return;
        }
        if (resp.responseStatus.code !== Status.Code.OK) {
          reject(resp.responseStatus.code);
          return;
        }
        self.setAuthenticated(true);
        resolve(resp);

        // reloading data
        if (endpoint === "getBookings") {
          self._callAllCallbacks(self.onDataChange, resp);
        } else {
          self.getBookings().then(
            () => null,
            () => null
          );
        }
      });
    });
  }

  getBookings() {
    let request = new GetBookingsRequest();
    return this._getRequestPromise("getBookings", request);
  }

  addBooking(booking) {
    let request = new CreateBookingRequest();
    request.setBooking(booking);
    return this._getRequestPromise("createBooking", request);
  }

  deleteBooking(bookingId) {
    let request = new DeleteBookingRequest();
    request.setBookingId(bookingId);
    return this._getRequestPromise("deleteBooking", request);
  }
}

export { Backend };
