import { createRouter, createWebHistory } from "vue-router";
import { createApp } from "vue";
import VCalendar from "v-calendar";
import ElementPlus from "element-plus";

import App from "./App.vue";
import LoginComponent from "./views/login.vue";
import MainComponent from "./views/main.vue";

import 'element-plus/dist/index.css'

const routes = [
  {
    path: "/",
    redirect: {
      name: "login"
    }
  },
  {
    path: "/login",
    name: "login",
    component: LoginComponent
  },
  { path: "/app", name: "main", component: MainComponent }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

createApp(App).use(router).use(VCalendar, {}).use(ElementPlus).mount("#app");
