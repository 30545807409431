<template>
  <el-container v-loading="showBusy">
    <el-header class="header-btn">
      <h2>Bookings for year {{ year }}</h2>
    </el-header>
    <el-main>
      <el-button
        icon="el-icon-plus"
        @click="showCreate = true"
        id="create"
        round
        type="primary"
        >New booking</el-button
      >
      <user-list />
      <calendar :year="$route.query.year" @busy="showBusy = $event" />
    </el-main>
    <el-dialog width="600px" :center="true" v-model="showCreate">
      <create-form :year="year" @close="showCreate = false" />
    </el-dialog>
  </el-container>
</template>

<script>
import Calendar from "../components/Calendar.vue";
import CreateForm from "../components/CreateForm.vue";
import UserList from "../components/UserList.vue";
export default {
  name: "Main",
  data() {
    return {
      showCreate: false,
      showBusy: false,
      year: new Date().getFullYear(),
    };
  },
  components: {
    Calendar,
    CreateForm,
    UserList,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 40px;
}
* {
  box-sizing: border-box;
}
.header-btn {
  position: relative;
}
#create {
  /* position: absolute;
  right: 15px;
  top: 15px; */
  display: block;
  margin: 0 auto 10px;
}
</style>