<template>
  <div id="userlist">
    <span v-for="user in usersList" :key="user.name" class="user">
      <span class="dot" :style="{ backgroundColor: user.color }"></span>
      {{ user.name }} ({{ user.days }})
    </span>
  </div>
</template>

<script>
export default {
  name: "UserList",
  inject: ["users"],
  computed: {
    usersList() {
      var usersSorted = Array.from(this.users.values());
      usersSorted.sort((a, b) => (a.name > b.name ? 1 : -1));
      return usersSorted;
    },
  },
  //inject: ["backend"],
  //methods: {},
};
</script>
<style scoped>
#userlist {
  display: block;
  margin: 10px auto;
}
.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}
.user {
  text-transform: capitalize;
  margin: 0 5px;
  display: inline-block;
}
</style>