<template>
  <div id="app">
    <el-container v-loading="showBusy">
      <el-header>
        <h1>"Impas" booking</h1>
        <div id="logout">
          <router-link
            v-if="authenticated"
            to="/login"
            @click="logout()"
            replace
            ><el-button icon="el-icon-circle-close" round
              >Logout</el-button
            ></router-link
          >
        </div></el-header
      >
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { ref, provide } from "vue";
import { Backend } from "./backend";
export default {
  name: "App",
  setup() {
    const backend = new Backend("https://lodka-proxy-oqksilapta-ew.a.run.app");
    const view = ref(null);
    provide("backend", backend);

    return {
      view,
      backend,
    };
  },
  data() {
    return {
      bookings: [],
      users: new Map(),
      authenticated: false,
      showBusy: false,
      colorSchema: [
        "red",
        "green",
        "gray",
        "purple",
        "blue",
        "yellow",
        "orange",
      ],
    };
  },
  provide() {
    return {
      bookings: this.bookings,
      users: this.users,
    };
  },
  created() {
    this.backend.addOnDataChange(this.onDataChange.bind(this));
    this.backend.addOnAuthChange(this.onAuthChange.bind(this));
    // Call API to getBookings and check auth
    this.showBusy = true;
    this.backend.getBookings().then(
      () => (this.showBusy = false),
      () => (this.showBusy = false)
    );
  },
  mounted() {
    if (!this.backend.isAuthenticed()) {
      this.$router.replace({ name: "login" });
    }
  },
  methods: {
    onAuthChange(state) {
      console.log("onAuthChange", state);
      this.authenticated = state;
      if (state === true) {
        this.$router.replace({ name: "main" });
      } else {
        this.$router.replace({ name: "login" });
      }
    },
    onDataChange(data) {
      console.log("onDataChange");
      while (this.bookings.length > 0) {
        this.bookings.pop();
      }
      this.users.clear();

      for (const b of data.bookingsList) {
        this.bookings.push(b);
        const owner = b.owner.toLowerCase();
        if (this.users.has(owner)) {
          this.users.get(owner).days +=
            1 + Math.round(b.endDate.seconds - b.startDate.seconds) / 86400;
          continue;
        }
        this.users.set(owner, {
          name: owner,
          days: 1 + Math.round(b.endDate.seconds - b.startDate.seconds) / 86400,
        });
      }
      this.assignColors();
    },
    assignColors() {
      var colorSchemaIdx = 0;
      var usersSorted = Array.from(this.users.values());
      usersSorted.sort((a, b) => (a.name < b.name ? 1 : -1));

      for (const idx in usersSorted) {
        this.users.get(usersSorted[idx].name).color =
          this.colorSchema[colorSchemaIdx] || "orange";
        colorSchemaIdx += 1;
      }
    },
    logout() {
      this.backend.clearAuthData();
    },
  },
};
</script>

<style>
body {
  background-color: #F0F0F0;
}
h1 {
  padding: 0;
  margin-top: 0;
}
#app {
  margin: auto;
}
#logout {
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 15px;
}
</style>