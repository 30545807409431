<template>
  <el-form
    ref="form"
    :model="input"
    :rules="rules"
    class="form"
    label-width="75px"
    v-loading="showBusy"
  >
    <el-form-item label="Owner" prop="owner">
      <el-input v-model="input.owner"></el-input>
    </el-form-item>
    <el-form-item label="Dates" prop="range">
      <el-date-picker
        v-model="input.range"
        type="daterange"
        :disabledDate="disabledDate"
        range-separator="-"
        start-placeholder="Start date"
        end-placeholder="End date"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item label="Comment">
      <el-input
        v-model="input.comment"
        placeholder="Optional comment"
        type="textarea"
      />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="add()">Create</el-button>
      <el-button @click="hide()">Cancel</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { Booking } from "../lodkapb/lodka_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb.js";

export default {
  name: "CreateForm",
  props: ["year"],
  data() {
    var checkDates = (rule, value, callback) => {
      console.log(value);
      if (!Array.isArray(value) || value.length !== 2) {
        return callback(new Error("Please select date range"));
      }
      if (
        value[0].getFullYear() !== this.year ||
        value[1].getFullYear() !== this.year
      ) {
        return callback(
          new Error("Select dates within " + this.year + " year.")
        );
      }
      callback();
    };

    return {
      showBusy: false,
      blockedDates: this.computeBlockDate(),
      input: {
        owner: this.backend.getUser(),
        range: [],
        comment: "",
      },
      rules: {
        owner: [
          {
            required: true,
            message: "Please specify owner",
            trigger: "change",
          },
        ],
        range: [{ validator: checkDates, trigger: "change" }],
      },
    };
  },
  watch: {
    bookings: {
      handler(val) {
        this.blockedDates = this.computeBlockDate(val);
      },
      deep: true,
    },
  },
  inject: ["backend", "bookings"],
  emits: ["close"],
  methods: {
    computeBlockDate(val) {
      console.log("aaaa");
      var dates = new Map();
      for (const idx in val || this.bookings) {
        var b = this.bookings[idx];

        // Start & end without first and last day
        var endDate = new Date((b.endDate.seconds - 86400) * 1000);
        var d = new Date((b.startDate.seconds + 86400) * 1000);
        while (d <= endDate) {
          dates.set(d.toDateString(), true);
          d.setDate(d.getDate() + 1);
        }
      }
      return dates;
    },
    disabledDate(date) {
      const start = new Date(this.year, 3, 1);
      const end = new Date(this.year, 11, 1);
      return (
        date < start ||
        date >= end ||
        this.blockedDates.get(date.toDateString())
      );
    },
    hide() {
      this.$emit("close");
    },
    add() {
      this.$refs["form"].validate((valid) => {
        console.log("valid", valid);
        if (!valid) {
          return false;
        }
        let booking = new Booking();
        booking.setCreator(this.backend.getUser());
        booking.setOwner(this.input.owner);
        booking.setStartDate(Timestamp.fromDate(this.input.range[0]));
        booking.setEndDate(Timestamp.fromDate(this.input.range[1]));
        booking.setComment(this.input.comment);
        this.showBusy = true;
        this.backend.addBooking(booking).then(
          () => {
            this.showBusy = false;
            this.input.owner = this.backend.getUser();
            this.input.range = [];
            this.input.comment = "";
            this.$emit("close");
          },
          (err) => {
            this.showBusy = false;
            alert(err);
          }
        );
      });
    },
  },
};
</script>
<style scoped>
.form {
  max-width: 500px;
  margin: 0 auto;
}
</style>