/**
 * @fileoverview gRPC-Web generated client stub for lodka
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.lodka = require('./lodka_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.lodka.LodkaServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.lodka.LodkaServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.lodka.GetBookingsRequest,
 *   !proto.lodka.GetBookingsResponse>}
 */
const methodDescriptor_LodkaService_GetBookings = new grpc.web.MethodDescriptor(
  '/lodka.LodkaService/GetBookings',
  grpc.web.MethodType.UNARY,
  proto.lodka.GetBookingsRequest,
  proto.lodka.GetBookingsResponse,
  /**
   * @param {!proto.lodka.GetBookingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.lodka.GetBookingsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.lodka.GetBookingsRequest,
 *   !proto.lodka.GetBookingsResponse>}
 */
const methodInfo_LodkaService_GetBookings = new grpc.web.AbstractClientBase.MethodInfo(
  proto.lodka.GetBookingsResponse,
  /**
   * @param {!proto.lodka.GetBookingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.lodka.GetBookingsResponse.deserializeBinary
);


/**
 * @param {!proto.lodka.GetBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.lodka.GetBookingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.lodka.GetBookingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.lodka.LodkaServiceClient.prototype.getBookings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/lodka.LodkaService/GetBookings',
      request,
      metadata || {},
      methodDescriptor_LodkaService_GetBookings,
      callback);
};


/**
 * @param {!proto.lodka.GetBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.lodka.GetBookingsResponse>}
 *     Promise that resolves to the response
 */
proto.lodka.LodkaServicePromiseClient.prototype.getBookings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/lodka.LodkaService/GetBookings',
      request,
      metadata || {},
      methodDescriptor_LodkaService_GetBookings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.lodka.CreateBookingRequest,
 *   !proto.lodka.CreateBookingResponse>}
 */
const methodDescriptor_LodkaService_CreateBooking = new grpc.web.MethodDescriptor(
  '/lodka.LodkaService/CreateBooking',
  grpc.web.MethodType.UNARY,
  proto.lodka.CreateBookingRequest,
  proto.lodka.CreateBookingResponse,
  /**
   * @param {!proto.lodka.CreateBookingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.lodka.CreateBookingResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.lodka.CreateBookingRequest,
 *   !proto.lodka.CreateBookingResponse>}
 */
const methodInfo_LodkaService_CreateBooking = new grpc.web.AbstractClientBase.MethodInfo(
  proto.lodka.CreateBookingResponse,
  /**
   * @param {!proto.lodka.CreateBookingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.lodka.CreateBookingResponse.deserializeBinary
);


/**
 * @param {!proto.lodka.CreateBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.lodka.CreateBookingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.lodka.CreateBookingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.lodka.LodkaServiceClient.prototype.createBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/lodka.LodkaService/CreateBooking',
      request,
      metadata || {},
      methodDescriptor_LodkaService_CreateBooking,
      callback);
};


/**
 * @param {!proto.lodka.CreateBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.lodka.CreateBookingResponse>}
 *     Promise that resolves to the response
 */
proto.lodka.LodkaServicePromiseClient.prototype.createBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/lodka.LodkaService/CreateBooking',
      request,
      metadata || {},
      methodDescriptor_LodkaService_CreateBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.lodka.DeleteBookingRequest,
 *   !proto.lodka.DeleteBookingResponse>}
 */
const methodDescriptor_LodkaService_DeleteBooking = new grpc.web.MethodDescriptor(
  '/lodka.LodkaService/DeleteBooking',
  grpc.web.MethodType.UNARY,
  proto.lodka.DeleteBookingRequest,
  proto.lodka.DeleteBookingResponse,
  /**
   * @param {!proto.lodka.DeleteBookingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.lodka.DeleteBookingResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.lodka.DeleteBookingRequest,
 *   !proto.lodka.DeleteBookingResponse>}
 */
const methodInfo_LodkaService_DeleteBooking = new grpc.web.AbstractClientBase.MethodInfo(
  proto.lodka.DeleteBookingResponse,
  /**
   * @param {!proto.lodka.DeleteBookingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.lodka.DeleteBookingResponse.deserializeBinary
);


/**
 * @param {!proto.lodka.DeleteBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.lodka.DeleteBookingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.lodka.DeleteBookingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.lodka.LodkaServiceClient.prototype.deleteBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/lodka.LodkaService/DeleteBooking',
      request,
      metadata || {},
      methodDescriptor_LodkaService_DeleteBooking,
      callback);
};


/**
 * @param {!proto.lodka.DeleteBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.lodka.DeleteBookingResponse>}
 *     Promise that resolves to the response
 */
proto.lodka.LodkaServicePromiseClient.prototype.deleteBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/lodka.LodkaService/DeleteBooking',
      request,
      metadata || {},
      methodDescriptor_LodkaService_DeleteBooking);
};


module.exports = proto.lodka;

